<template>
  <div>
    <div class="content px-6">
      <div
        class="f-s-24 f-w-900 px-0 py-2"
        v-html="layouts.data.title"
      >
      </div>
      <div
        class="subtitle pa-0"
      >
        {{ layouts.data.secondTitle }}
      </div>

      <div v-min-height="400">
        <v-card flat class="bg-accent px-4" width="100%">
          <v-card-actions>
            <v-icon size="100" color="var(--theme_secondary)">mdi-email-outline</v-icon>
          </v-card-actions>
          <ValidationObserver ref="observer" class="email">
            <ValidationProvider
              name="Email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Email"
                :error-messages="errors[0]"
                @keydown.enter="onClickContinue"
                v-model="dataSources.current.email"
              ></v-text-field>
            </ValidationProvider>
          </ValidationObserver>
        </v-card>
      </div>
    </div>

    <div class="footer">
      <wap-btn :height="50" width="100%" @click="onClickContinue">
        Continue
      </wap-btn>
    </div>
  </div>
</template>

<script>
import { OnlineApi } from '@/api'
import { Tool } from '@/assets/js/util'
import WapBtn from '@/components/base/WapBtn'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    WapBtn,
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      assists: {
        tool: new Tool()
      },
      dataSources: {
        current: {
          email: ''
        }
      },
      layouts: {
        data: {
          title: '',
          secondTitle: 'Please input your email to continue.'
        }
      }
    }
  },
  watch: {
    'store.getters.getPortfolioJson.name': {
      handler: function (val) {
        if (this.assists.tool.isNotEmpty(val)) {
          this.layouts.data.title = 'Welcome to ' + '<br>' + val + '!'
        }
      }
    }
  },
  mounted () {
    this.$store.commit('setRedirectUrlParameter', this.$route.path)
    const portfolio = this.$store.getters.getPortfolioJson
    if (this.assists.tool.isNotEmpty(portfolio)) this.layouts.data.title = 'Welcome to ' + '<br>' + portfolio.name + '!'
  },
  methods: {
    onClickContinue () {
      this.$refs.observer.validate().then((validResult) => {
        if (validResult) {
          OnlineApi.authenticateIdentity(
            {
              identifier: this.$route.params.identifier,
              email: this.dataSources.current.email
            },
            (res) => {
              this.$store.commit('setCouponDetails', res.couponDetails)
              this.$store.commit('setCouponCode', res.couponCode)
              this.$store.commit('setCollectionLoanNo', res.loanNo)
              this.$store.commit('setCollectionPortfolioId', res.portfolioId)
              this.checkStatus(res)
            },
            (failure) => {
              this.setErrorMessage(failure)
            }
          )
        }
      })
    },
    checkStatus (res) {
      OnlineApi.checkCollectionStatus(
        {
          loanNo: res.loanNo,
          targetStatus: 205001
        },
        (data) => {
          this.$router.push('/coupon/overview')
        },
        (failure) => {
          this.setErrorMessage(failure)
        }
      )
    },
    setErrorMessage (err) {
      this.$store.commit('setPopupInformation', {
        message: err.message
      })
    }
  }
}
</script>

<style lang='sass' scoped>
.content
  margin-top: 64px
.bg-accent
  background-color: rgba(244, 245, 249) !important
  color: #464C5B
  border-radius: 10px
.text
  font-size: 16px
  color: #464C5B

.email
  width: 100%
</style>
